@import url("/src/styles.modyle.css");

.platforms {
    padding: 200px 0;
    width: 100%;
    background: #455A82;
    @media screen and (max-width: 1700px) and (min-width: 744px) {
        padding: 160px 0;
    }
    @media screen and (max-width: 744px) {
        padding: 140px 0;
    }
}

.platforms__container {
    color: var(--main0);
    display: flex;
    justify-content: space-between;
    padding: 0 200px;
    @media screen and (max-width: 1440px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 100px;
        br {
            display: none;
        }
        div.platforms__content-wrapper {
            margin: 0;
            max-width: 100%;
            max-height: 100%;
            .platforms__title {
                text-align: center;
            }
            .platforms__subtitle {
                text-align: center;
                margin-bottom: 120px;
            }
            .platforms__button-wrapper {
                margin-bottom: 80px;
                align-items: center;
                justify-content: center;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0 60px;
        div.platforms__content-wrapper {
            .platforms__subtitle {
                text-align: center;
                margin-bottom: 60px;
            }
        .platforms__button-wrapper {
            margin-bottom: 32px;
        }
        }
    }
    @media screen and (max-width: 744px) {
        padding: 0 20px;
        .platforms__slider {
            max-width: 370px;
            max-height: 380px;
            .platforms__slider-wrapper {
                gap: 18px;
                div.platforms__item {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: space-between;
                    min-width: 360px;
                    width: 360px;
                    height: 360px;
                    margin-left: 9px;
                    img {
                        max-width: 320px;
                        max-height: 320px;
                        background-repeat: no-repeat;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        padding: 0 20px;
        .platforms__slider {
            max-width: 304px;
            max-height: 304px;
            .platforms__slider-wrapper {
                gap: 18px;
                div.platforms__item {
                    img {
                        max-width: 260px;
                        max-height: 260px;
                        margin: 0;
                    }
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: space-between;
                    min-width: 300px;
                    width: 300px;
                    height: 300px;
                    padding: 24px;
                    margin-left: 1px;

                }
            }
        }
    }
   .platforms__content-wrapper {
    max-width: 658px;
    max-height: 620px;
    margin-right: 160px;
    .platforms__title {
        color: var(--white);
        text-align: left;
        margin-bottom: 16px;
        width: 100%;
    }
    .platforms__subtitle {
        text-align: left;
        color: var(--white);
        margin-bottom: 200px;
    }

    .platforms__button-wrapper {
        display: flex;
        gap: 24px;
        .platforms__button {
            background: transparent;
            border: 0;
            cursor: pointer;
            .arrow-left-circle {
                width: 100px;
                color: #EDE8FA;
                @media screen and (max-width: 960px) {
                    width: 60px;
                 }
                &:hover {
                    color: #DDC2F4;
                }
            }

            &:disabled .arrow-left-circle {
                color: #EDE8FA;
                opacity: 0.6;
            }
            
            .arrow-right-circle {
                width: 100px;
                color: #EDE8FA;
                @media screen and (max-width: 960px) {
                    width: 60px;
                 }
                &:hover {
                    color: #DDC2F4;
                }
            }

            &:disabled .arrow-right-circle {
                color: #EDE8FA;
                opacity: 0.6;
            }
              
        }
    }
   }

}

.platforms__slider {
    overflow: hidden;
    max-width: 510px;
    max-height: 500px;

    .platforms__slider-wrapper {
        display: flex;
        gap: 36px; 
        transition: transform 0.5s ease-in-out; 
        .platforms__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            min-width: 500px;
            width: 500px;
            height: 500px;
            border-radius: 50px;
            background: linear-gradient(0deg, #ECDBFB 0%, #ECDBFB 100%);
            img {
                max-width: 500px;
                max-height: 500px;
                background-repeat: no-repeat;
                margin-bottom: 16px;
            }
            &:nth-child(2),
            &:nth-child(4) {
                background: linear-gradient(0deg, #DDC2F4 0%, #DDC2F4 100%)
            }
            .platforms__item-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                @media screen and (max-width: 744px) {
                    justify-content: center;
                    align-items: center;
                }
            }
            div {
                display: flex;
                align-items: start;
                justify-content: start;
            }
            h3 {
                color: #292929;
                text-align: left;
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
                margin-bottom: 32px;
            }
            p {
                color: #292929;
                text-align: left;
            }
        }
    }
}

.platforms__item-windows {
    width: 90px;
    height: 90px;
    background: url('../../icons/platforms/icon-windows.svg') no-repeat center center;
    background-size: contain;
}

.platforms__item-avrora {
    width: 90px;
    height: 90px;
    background: url('../../icons/platforms/icon-avrora.svg') no-repeat center center;
    background-size: contain;
}

.platforms__item-ios {
    width: 90px;
    height: 90px;
    background: url('../../icons/platforms/icon-ios.svg') no-repeat center center;
    background-size: contain;
}

.platforms__item-android {
    width: 90px;
    height: 90px;
    background: url('../../icons/platforms/icon-android.svg') no-repeat center center;
    background-size: contain;
    fill: var(--transparent5);
}

.platforms-tab-blue {
    position: absolute;
    top: 28px;
    left: 32px;
    height: 42px;
}