@import url("/src/styles.modyle.css");

.confidentiality {
    width: 100%;
    background-color: #fff;
}

.confidentiality__container {
    margin: 0 auto;
    padding: 160px 200px;
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        padding: 60px 60px;
    }
    @media screen and (max-width: 744px) {
        padding: 60px 20px;
    }
}

.confidentiality__title {
    word-break: break-word;
    margin-bottom: 120px;
    u {
        color: #02A2E1;
    }
    @media screen and (max-width: 744px) {
        margin-bottom: 60px;
    }
}           

.confidentiality__slider {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 120px;
    @media screen and (max-width: 1260px){
        padding: 0;
    }
    @media screen and (max-width: 744px) {
        flex-direction: column;
        .confidentiality__slider_wrapper {
            order: 3;
            width: 100%;
            height: 100%;
        }
        .confidentiality__slide__img_wrapper {
            order: 1;
        }
        .confidentiality__slider_dotlist {
            order: 2;
            flex-direction: row;
            padding-top: 16px;

            .dotItem_active {
                width: 16px;
                height: 32px;
                border-radius: 30px;
                transform: rotate(-90deg);
                margin: 0 8px;
            }    

        }
        .slide__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            transition: transform 0.5s ease-in-out;
            height: 100%;
            position: relative;
            gap: 0;
            &::before {
                background: none;
            }
            &::after {
                background: none;
            }
            .confidentiality__slide-item {
                h4 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: none;
                }
            }
        }
    }
}

.confidentiality__slider_wrapper {
    display: flex;
    width: 100%;
    height: 700px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.slide__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    transition: transform 0.5s ease-in-out;
    height: 100%;
    position: relative;
    &::before {
        content: "";
        width: 100%;
        left: 0;
        top: 0;
        height: 200px;
        background: linear-gradient(180deg, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        pointer-events: none;
        z-index: 1;
    }
    &::after {
        content: "";
        width: 100%;
        left: 0;
        bottom: 0;
        height: 200px;
        background: linear-gradient(0deg, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        pointer-events: none;
        z-index: 1;
    }
}

.confidentiality__slide-item {
    padding: 30px;
    color: var(--main700);
    text-align: left;
    border-radius: 60px;
    transition: all 0.7s;
    cursor: pointer;
    background: white;
    h4 {
        margin-bottom: 1rem;
    }
    &:hover {
        color: var(--main500);
        transform: scale(1.02);
    }
}

.confidentiality__slide__img_wrapper {
    position: relative;
    overflow: hidden;
    width: 342px;
    height: 700px;
    @media screen and (max-width: 744px) {
        width: 180px;
        height: 368px;
        margin-left: 0;
    }
}

.confidentiality__slide__img_wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 100%;
    opacity: 0;
    transition:
        top 0.5s ease-in-out,
        opacity 0.5s ease-in-out;
}

.confidentiality__slide__img_wrapper img.active {
    top: 0;
    opacity: 1;
}

.confidentiality__slider_dotlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
    @media screen and (max-width: 744px) {
        margin-left: 0;
    }
}

.confidentiality__slider_dotlist span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--transparent2);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confidentiality__slider_dotlist .dotItem_active {
    background: var(--transparent30);
    width: 16px;
    height: 55px;
    border-radius: 30px;
}
