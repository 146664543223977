@import url("/src/styles.modyle.css");

.main__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(-15.64deg, rgba(255, 241, 245, 0.88) 0.025%, rgba(8, 34, 85, 0.26) 99.903%),
        rgb(42, 73, 119);
    margin: 0 auto;
}

.header,
.footer {
    color: var(--white);
}

svg {
    background-repeat: no-repeat;
    outline: none;
    border: 0;
    list-style: none;
}

.tag {
    padding: 8px 24px 10px 24px;
    display: inline;
    border-radius: 0.5rem;
    &.blue {
        background: var(--main0);
        color: var(--main400);
    }
    &.green {
        background: var(--green0);
        color: var(--green400);
    }
    &.orange {
        background: var(--orange0);
        color: var(--orange400);
    }
    &.red {
        background: var(--red0);
        color: var(--red400);
    }
    &.cyan {
        background: var(--info0);
        color: var(--info-tertiary);
    }
}

/* .header__container, */
.hero,
.hero__container,
.mission__container,
.possibilities__container,
.functions-messenger__container,
.conference-calls__container,
.protect__container,
.registry__container,
.platforms__container,
.tariffs__container,
.faq__container,
.details__container,
.contact-form__container {
    max-width: 1920px;
    margin: 0 auto;
}

.conference-calls,
.possibilities,
.confidentiality,
.protect__container,
.registry,
.platforms,
.tariffs,
.faq,
.details,
.contact-form {
    margin: 0 auto;
}

.button {
    display: flex;
    padding: 20px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 3px #1a396e;
    color: var(--main500);
    white-space: nowrap;
    cursor: pointer;
}

.button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--white);
    text-decoration: none;
    text-align: center;
    background: #c6d6ff;
    color: var(--main500);
    white-space: nowrap;
}

.button-border {
    border: solid 3px #1a396e;
}

.button-link-background {
    background: #c6d6ff;
}

.button-contact {
    background: var(--white);
}

.button-arrow-right-up-line {
    width: 24px;
    height: 24px;
    background-image: url(../../icons/header/arrow-right-up-line-24.svg);
    @media screen and (max-width: 744px) {
        width: 16px;
        height: 16px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.button-download {
    width: 24px;
    height: 24px;
    background-image: url(../../../public/download-icon.svg);
    @media screen and (max-width: 744px) {
        width: 16px;
        height: 16px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.button-edit {
    width: 24px;
    height: 24px;
    background-image: url(../../icons/header/edit-24.svg);
    @media screen and (max-width: 744px) {
        width: 16px;
        height: 16px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.button-text {
    color: var(--main500);
    text-align: center;
}
.mobile {
    display: none;
}

/*----------------- Header Serction*/

.header__container {
    display: flex;
    width: 100% !important;
    padding: 0 60px;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 80px;
    gap: 54px;
    transition:
        background-color 0.3s,
        color 0.3s,
        border-radius 0.3s;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        margin: 32px 0 60px;
        padding: 0 42px;
    }

    @media screen and (max-width: 1024px) and (min-width: 744px) {
        margin: 32px 0 60px;
        padding: 0 20px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        margin: 12px 0 16px;
        padding: 0 20px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        margin: 12px 0 16px;
        padding: 0 20px;
    }
    @media screen and (max-width: 320px) {
        margin: 12px 0 16px;
        padding: 0 10px;
    }

    .header__logo {
        padding-top: 5px;
    }
    &.header__container_scrolled {
        display: flex;
        width: 100% !important;
        padding: 0 60px;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;
        gap: 54px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: var(--white);
        color: var(--main400);
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        @media screen and (max-width: 1440px) {
            padding: 24px 20px;
        }

        .burger-menu {
            display: grid;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 8px;
            background: var(--main500);
            width: 44px;
            height: 44px;
            padding: 14px;
            &.open {
                display: none;
            }
            .burger-bar {
                width: 16px;
                border-bottom: var(--white) 3px solid;
            }
        }

        @media screen and (min-width: 1441px) {
            .burger-menu {
                display: none;
            }
        }
    }
}

.burger-menu {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
}
.burger-bar {
    width: 30px;
    border-bottom: var(--main500) 3px solid;
    transition: all 0.3s ease;
}

@media screen and (max-width: 1440px) {
    .header__nav_open .mobile {
        padding: 28px 20px 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .burger-menu-open {
            width: 24px;
            height: 24px;
            position: relative;
            cursor: pointer;
            .burger-bar {
                position: absolute;
                width: 16px;
                height: 2px;
                left: 50%;
                top: 50%;
                &:nth-child(1) {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &:nth-child(2) {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }
}
.header__nav_open {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1440px) {
        .header__list {
            padding: 0;
            border-radius: 0;
            flex-direction: column;
            flex: 1;
            align-items: stretch;
            gap: 0;
            background-color: var(--white);
            justify-content: start;
            padding: 100px 0 60px;
            & .header__item {
                padding: 14px 24px;
            }
            @media screen and (max-width: 1440px) and (min-width: 1024px) {
                /* gap: 42px; */
                padding: 100px 0 0;
                & .header__item:last-child {
                    margin-top: 100px;
                }
            }
        
            @media screen and (max-width: 1024px) and (min-width: 744px) {
                /* gap: 32px; */
                padding: 100px 0 0;
                & .header__item:last-child {
                    margin-top: 100px;
                }
            }
            @media screen and (max-width: 744px) {
                /* gap: 22px; */
                & .button.button-contact {
                    padding: 12px 36px;
                }
                & .header__item:last-child {
                    margin-top: 80px;
                }
            }
            @media screen and (max-width: 420px) and (min-width: 320px) {
                gap: 16px;
                & .header__item:last-child {
                    margin-top: 100px;
                }
            }
            @media screen and (max-width: 320px) {
                gap: 8px;
                padding: 20px 0;
                & .header__item {
                    padding: 14px 24px;
                }
                & .header__item:last-child {
                    margin-top: 32px;
                }
            }

            @media screen and (max-width: 744px) {
                .button-link-background{
                    display: none;
                }
                .button-contact {
                    width: 300px;
                    height: 40px;
                }
            }

            .button {
                padding: 20px 36px;
            }
            
            .header__item a {
                color: var(--main400);
            }
            button .button-text {
                display: inline;
            }
            & .subtitle_L {
                font-size: 42px;
                font-weight: 700;
                line-height: 66px;
                @media screen and (max-width: 1440px) and (min-width: 1025px) {
                    font-size: 42px;
                    line-height: 56px;
                }
                @media screen and (max-width: 1024px) and (min-width: 744px) {
                    font-size: 32px;
                    line-height: 46px;
                }
                @media screen and (max-width: 744px) and (min-width: 420px) {
                    font-size: 24px;
                    line-height: 36px;
                }
                @media screen and (max-width: 420px) and (min-width: 320px) {
                    font-size: 22px;
                    line-height: 32px;
                }
                @media screen and (max-width: 320px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media (max-width: 1440px) {
    .header__nav {
        position: absolute;
        top: -100vh;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.9);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
    }
    .header__nav_open {
        top: 0;
        background: rgb(255, 255, 255);
        z-index: 2000;
    }

    .burger-menu {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: var(--white);
        width: 44px;
        height: 44px;
        padding: 14px;
        &.open {
            display: none;
        }
        .burger-bar {
            width: 16px;
        }
    }
}

.logo {
    width: 150px;
    height: 30px;
    background-image: url("../../../public/keepChat-logo.svg");
    background-repeat: no-repeat;
    outline: none;
    background-size: contain;
    @media screen and (max-width: 744px) {
        width: 80px;
        height: 15px;
    }

    &.logo_scrolled {
        background-image: url("../../../public/keepChat-logo-blue.svg");
    }
}

.header__nav {
    width: 100%;
}

.header__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 0;
    /* gap: 0.5rem; */
    list-style: none;
    border-radius: 24px;
    background: rgba(255, 242, 207, 0.15);
    backdrop-filter: blur(2px);
}

.header__item {
    display: flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--white);
    text-align: center;
    @media screen and (min-width: 1024px) and (max-width: 1770px) {
        .button {
            padding: 12px;
            border-radius: 16px;

            .button-text {
                display: none;
            }
        }
    }
}

.header__item a:hover {
    opacity: 0.6;
}

.header__item_padding_non {
    padding: 0;
    display: flex;
    gap: 16px;
}

.header__item_padding_non button {
    border: solid 3px #1a396e;
}

.header__link {
    color: var(--white);
    text-align: center;
    text-decoration: none;
    gap: 10px;
    &.header__link_scrolled {
        color: var(--main400);
    }
}

/* ----------------- Hero Section */

.hero {
    width: 100%;
}

.hero__container {
    margin: 0 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 400px;
    gap: 30px;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        margin: 0 42px;
    }

    @media screen and (max-width: 1024px) and (min-width: 744px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 400px;
        gap: 24px;
        margin: 0 20px;
          br { 
            display: none;
          }  
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 240px 300px;
        gap: 16px;
        margin: 0 20px;
        br { 
            display: none;
          }  
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 180px 180px;
        gap: 8px;
        margin: 0 20px;
        br { 
            display: none;
          }  
    }
    @media screen and (max-width: 320px) {        
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 120px;
        margin: 0 10px;
        gap: 4px;
          br { 
            display: none;
          } 
    }
    .hero__card {
        border-radius: 50px;
        &:nth-child(1) {
            padding: 42px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--white);
            border-bottom-right-radius: 0;
            background: rgba(255, 255, 255, 0.15);
            h3 {
                margin-bottom: 24px;
            }
        }
        @media screen and (max-width: 1440px) and (min-width: 1024px) {
            &:nth-child(1) {
                padding: 42px;
            }
        }
        @media screen and (max-width: 1024px) and (min-width: 744px) {
            &.hero__card-content {
                padding: 60px;
            }
            & .button.button-contact {
                width: 240px;
                height: 68px;
                margin: 80px 0 0;
            }
            &:nth-child(1) {
                border-bottom-right-radius: 50px;
                grid-column: 1 / 4;
                h3 {
                    margin-bottom: 36px;
                }
            }
            &:nth-child(2) {
                grid-column: 3;
                grid-row: 2;
            }
    
            &:nth-child(3) {
                grid-column: 1;
                grid-row: 2;
            }
    
            &:nth-child(4) {
                grid-column: 2;
                grid-row: 2;
            }
        }
        @media screen and (max-width: 744px) and (min-width: 420px) {    
            border-radius: 16px;
            & .button.button-contact {
                max-width: 284px;
                height: 44px;
            }
            &:nth-child(1) {
                grid-column: 1 / 3;
                background: transparent;
                padding: 0;
                .button-contact {
                    margin-top: 36px;
                }
                h3 {
                    margin-bottom: 20px;
                }
            }
            &:nth-child(2) {
                grid-column: 1 / 3;
                grid-row: 3;
            }
    
            &:nth-child(3) {
                grid-column: 1;
                grid-row: 2;
            }
    
            &:nth-child(4) {
                grid-column: 2;
                grid-row: 2;
            }
        }
        @media screen and (max-width: 420px) and (min-width: 320px) {
            border-radius: 16px;
            & .button.button-contact {
                width: 100%;
                height: 40px;
                margin: 24px auto;
            }
            &:nth-child(1) {
                padding: 0;
                grid-column: 1 / 3;
                background: transparent;
                align-items: stretch;
                h3 {
                    margin-bottom: 24px;
                }
            }
            &:nth-child(2) {
                grid-column: 1;
                grid-row: 2;
            }
    
            &:nth-child(3) {
                grid-column: 2;
                grid-row: 2;
            }
    
            &:nth-child(4) {
                grid-column: 1 / 3;
                grid-row: 3;
            }
        }
        @media screen and (max-width: 320px) {
            border-radius: 16px;
            & .button.button-contact {
                width: 100%;
                height: 40px;
                margin: 6px auto;
            }
            &:nth-child(1) {
                padding: 0;
                grid-column: 1 / 4;
                background: transparent;
                align-items: stretch;
                h3 {
                    margin-bottom: 16px;
                }
            }
            &:nth-child(2) {
                grid-column: 1;
                grid-row: 2;
            }
    
            &:nth-child(3) {
                grid-column: 2;
                grid-row: 2;
            }
    
            &:nth-child(4) {
                grid-column: 3;
                grid-row: 2;
            }
        }
        &:nth-child(2) {
            background: center center/cover no-repeat url("../../../public/here_img_1.jpg");
            border-bottom-left-radius: 0;
        }
        &:nth-child(3) {
            background: center center/cover no-repeat url("../../../public/here_img_2.jpg");
            border-top-right-radius: 0;
        }
        &:nth-child(4) {
            background: center center/cover no-repeat url("../../../public/here_img_3.jpg");
            border-top-left-radius: 0;
        }
    }
}

.hero .button {
    margin-top: 80px;
    border: solid 3px var(--main700);
}

/* ----------------- Mission Section */

.mission {
    overflow: hidden;
}

.mission__container {
    padding: 160px 200px 80px;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        padding: 120px 20px 80px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        padding: 42px 20px;
    }
    @media screen and (max-width: 320px) {
        padding: 42px 10px;
    }
}

.mission__title {
    color: var(--white);
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    text-align: start;
    &:nth-of-type(2) {
        color: #cebdff;
        font-style: normal;
        font-weight: 600;
        text-decoration-line: underline;
    }
}

h1.mission__title {
    @media screen and (max-width: 560px) and (min-width: 420px) {
     font-size: 36px;
     line-height: 48px;
    }  
}
/* ----------------- Advantages Section */

.advantages {
    width: 100%;
    overflow: hidden;
    padding-top: 200px;
    padding-bottom: 155px;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        padding-top: 90px;
        padding-bottom: 166px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        padding-top: 150px;
        padding-bottom: 120px;
        margin-top: -150px;
    }
}

.advantages__container {
    padding: 0 200px;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 0 32px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        padding: 0 60px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        padding: 0 10px;
    }
    @media screen and (max-width: 320px) {
        padding: 0 10px;
    }
}

.advantages__title {
    margin-bottom: 16px;
}

.advantages__item {
    display: flex;
    align-items: center;
    gap: 60px;
    color: var(--white);
    margin-bottom: 76px;
    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        margin-bottom: 100px;
        &:nth-child(2) {
            .advantages__img_wrapper::after {
                left: 50% !important;
            }
        }
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
        gap: 16px;
        padding: 16px 0;
        &:nth-child(2) {
            .advantages__img_wrapper::after {
                left: 80% !important;
                top: -200% !important;
            }
        }
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
        gap: 16px;
        padding: 16px 0;
        &:nth-child(2) {
            .advantages__img_wrapper::after {
                left: 80% !important;
                top: -200% !important;
            }
        }
    }
    @media screen and (max-width: 320px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
        gap: 16px;
        padding: 16px 0;
        &:nth-child(2) {
            .advantages__img_wrapper::after {
                left: 80% !important;
                top: -200% !important;
            }
        }
    }
}

.advantages__img_wrapper {
    width: 200px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        z-index: 2;
    }
    &::after {
        content: "";
        width: 500px;
        height: 500px;
        position: absolute;
        left: -150px;
        top: -200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;
        opacity: 0.15;
        filter: blur(10px);
    }
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        &::after {
            width: 320px;
            height: 320px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        width: auto;
        img {
            width: 126px;
            height: 126px;
        }
        &::after {
            width: 332px;
            height: 332px;
            left: -120%;
            top: -100%;
        }
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        width: 50%;
        height: auto;
        img {
            width: 60px;
            height: 60px;
        }
        &::after {
            width: 175px;
            height: 175px;
            top: -250%;
            left: -40%;
        }
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        width: 50%;
        height: auto;
        img {
            width: 60px;
            height: 60px;
        }
        &::after {
            width: 175px;
            height: 175px;
            top: -250%;
            left: -40%;
        }
    }
    @media screen and (max-width: 320px) {
        width: 50%;
        height: auto;
        img {
            width: 60px;
            height: 60px;
        }
        &::after {
            width: 175px;
            height: 175px;
            top: -250%;
            left: -40%;
        }
    }
}

.advantages__item:nth-child(1) .advantages__img_wrapper::after {
    background-image: url("./../../../public/icon_1.svg");
}

.advantages__item:nth-child(2) .advantages__img_wrapper::after {
    background-image: url("./../../../public/icon_2.svg");
    left: -70px;
}
.advantages__item:nth-child(2) .advantages__img_wrapper {
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        &:after {
            left: -130% !important;
            top: -30%;
        }
    }
}

.advantages__item:nth-child(3) .advantages__img_wrapper::after {
    background-image: url("./../../../public/icon_3.svg");
}
.advantages__item:nth-child(3) .advantages__img_wrapper {
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        &::after {
            top: 20%;
            left: -20%;
        }
    }
}

.advantages__item:nth-child(2) {
    justify-content: end;
    .advantages__text_content_li {
        display: inline-flex;
    }
    .advantages__img_wrapper {
        order: 2;
    }
    text-align: end;
    @media screen and (max-width: 744px) and (min-width: 420px) {
        .advantages__img_wrapper {
            order: 1;
        }
        .advantages_item__text_content {
            order: 2;
        }
        text-align: center;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        .advantages__img_wrapper {
            order: 1;
        }
        .advantages_item__text_content {
            order: 2;
        }
        text-align: center;
    }
    @media screen and (max-width: 320px) {
        .advantages__img_wrapper {
            order: 1;
        }
        .advantages_item__text_content {
            order: 2;
        }
        text-align: center;
    }
}

.advantages_item__text_content {
    z-index: 3;
}

.advantages__text_content_ul {

    @media screen and (max-width: 1024px) {
        margin-top: 16px;
    }
}

.advantages__text_content_li {
    position: relative;
    padding-left: 26px;
    &::before {
        content: "";
        left: 0;
        top: 15px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: var(--white);
        position: absolute;
    }
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        &::before {
            top: 15px;
        }
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        &::before {
            top: 13px;
        }
    }
    @media screen and (max-width: 744px) {
        padding-left: 7px;
        display: block !important;
        text-align: center;
        &::before {
            content: none;
        }
    }
}

/* ----------------- Possibilities Section */

.possibilities {
    background: var(--white);
    width: 100%;
}

.possibilities__container {
    padding: 120px 290px;

    @media screen and (max-width: 1440px) and (min-width: 960px) {
        padding: 200px 60px;
    }
    @media screen and (max-width: 960px) and (min-width: 744px) {
        padding: 80px 20px 120px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        padding: 80px 20px 120px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        padding: 60px 20px;
    }
    @media screen and (max-width: 320px) {
        padding: 60px 10px;
    }
}

.possibilities__title {
    text-align: center;
    color: var(--main700);
}

.possibilities__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
    color: var(--main700);
    gap: 120px;
    &:first-child {
        margin-bottom: 120px;
    }
    p {
        max-width: 658px;
        margin-bottom: 1rem;
        &:nth-child(3) {
            margin-bottom: 60px;
        }
    }
    h3 {
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        margin-top: 0;
        margin-bottom: 0;
        gap: 50px;
        &:first-child {
            margin-bottom: 60px;
        }
        h3 {
            text-align: center;
        }
        p {
            text-align: center;
            max-width: 100%;
            margin-bottom: 24px;
            &:nth-child(3) {
                margin-bottom: 24px;
            }
        }
        .col {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .col-2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:first-child {
               order: 2;
            }
            &:last-child {
                order: 1;
            }
        }
    }

    @media screen and (max-width: 744px) and (min-width: 420px) {
        margin-top: 0;
        margin-bottom: 0;
    }
    .col {
        width: 100%;
        &:last-child {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        @media screen and (min-width: 1024px) {
            img {
                width: 344px;
                height: 700px;
            }
        }
        @media screen and (max-width: 1024px) and (min-width: 744px) {
            img {
                width: 328px;
                height: 707px;
            }    
        }
        @media screen and (max-width: 744px) and (min-width: 420px) {
            img {
                width: 192px;
                height: 390px;
                &:last-child {
                    width: 215px;
                }
            }           
        }
        @media screen and (max-width: 420px) and (min-width: 320px) {
            img {
                width: 192px;
                height: 390px;
            }  
        }
        @media screen and (max-width: 320px) {
            img {
                width: 192px;
                height: 390px;
            }  
        }
    }

    .col-2 {
        width: 100%;
        &:first-child {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        @media screen and (min-width: 1024px) {
            img {
                width: 360px;
                height: 700px;
            }
        }
        @media screen and (max-width: 1024px) and (min-width: 744px) {
            img {
                width: 480px;
                height: 707px;
            }        
        }
        @media screen and (max-width: 744px) and (min-width: 420px) {
            img {
                width: 330px;
                height: 430px;
            }   
        }
        @media screen and (max-width: 420px) and (min-width: 320px) {
            img {
                width: 220px;
                height: 390px;
            }  
        }
        @media screen and (max-width: 320px) {
            img {
                width: 192px;
                height: 390px;
            }  
        }
    }
}

.possibilities__text {
    font-size: 18px;
}

/* ----------------- Conference-calls Section*/

.conference-calls {
    width: 100%;
    background: url("../../icons/conference-calls/conference-calls-background.svg") top/cover no-repeat;
    width: 100%;
    height: 100%;
    .conference-calls__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 160px 0;
        img {
            width: 896px;
            margin: 0 0 60px;
        }
        @media screen and (max-width: 1440px) {
            padding: 80px 20px;
            img {
                width: 800px;
                padding: 60px 20px;
            }
        }
        @media screen and (max-width: 860px) {
            .conference-calls__title {
                margin: 0;
            }
            .conference-calls__subtitle {
                margin: 0;
            }
            padding: 80px 0;
            img {
                width: 600px;
                padding: 60px 0;
                margin-right: 300px;
            }
        }
    }
    .conference-calls__title {
        color: var(--white);
        text-align: center;
        margin-bottom: 120px;
    }
    .conference-calls__subtitle {
        text-align: center;
        color: var(--white);
    }
}

/* ----------------- Protect Section*/

.protect {
    width: 100%;
    background: linear-gradient(110deg, rgba(179, 25, 66, 0.2) 6.4%, rgba(0, 0, 0, 0.2) 94.38%), #0f0c30;
}

.protect__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 160px 60px;
    flex-direction: column;

    @media screen and (max-width: 1600px) {
        .protect__wrapper {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
        }
        .protect__contect-list {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            max-width: 100%;
            height: 100%;
            .contect-list__item {
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 436px;
                width: 100%;
                max-width: 924px;
                position: relative;
                margin-right: 60px;
            }
        }

    }
    @media screen and (max-width: 744px) {
        padding: 140px 20px;
    }
}

.protect__wrapper {
    margin-right: 80px;
    max-width: 900px;
}

.protect__title {
    color: var(--white);
    text-align: center;
    margin-bottom: 24px;
}

.protect__subtitle {
    text-align: center;
    color: var(--white);
    margin-bottom: 100px;
}

.protect__contect-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 580px;
    width: 100%;
    height: 316px;
    position: relative; 
    .contect-list__item {
        display: flex;
        align-items: center;
        max-height: 316px;
        max-width: 670px;
        position: absolute;
        top: 0;
        right: -40px;
    }
}

/* ----------------- Registry Section*/

.registry {
    padding: 160px 200px;
    width: 100%;
    background: linear-gradient(39deg, rgba(255, 241, 245, 0.88) 38.39%, rgba(8, 34, 85, 0.26) 81.1%), #2a4977;
    @media screen and (max-width: 1440px) {
        padding: 200px 60px;
        .registry__container {
            padding: 120px 60px;
            .registry__list-wrapper {
                .registry__item {
                    &:first-of-type {
                        position: relative;
                        left: 0;
                        height: 400px;
                        padding: 20px 40px;
                    }                   
                     &:last-of-type {
                        position: relative;
                        right: 0;
                        height: 400px;
                        padding: 20px 60px;
                    }
                    div {
                        img {
                            margin-bottom: 10px;
                            @media screen and (max-width: 744px) {
                                max-width: 260px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1220px) {
        padding: 0;
        .registry__container {
            border-radius: 0;
            padding: 100px 20px;
            .registry__list-wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                .registry__item {
                    width: 100%;
                    &:first-of-type {
                        height: 100%;
                    }
                    &:last-of-type {
                        height: 100%;
                    }
                }
            }
        }
    }

}

.registry__container {
    color: var(--main700);
    display: flex;
    flex-direction: column;
    padding: 120px 120px 180px;
    border-radius: 60px;
    background-color: var(--main700);

    .registry__title {
        width: 100%;
        color: var(--white);
        text-align: center;
        font-weight: 700;
    }
    .registry__title_underline {
        color: #ea5a81;
        text-decoration-line: underline;
    }
    .registry__subtitle {
        color: var(--white);
        text-align: center;  
    }
    .registry__subtitle_s {
        color: var(--white);
        text-align: center;
        padding: 42px 0; 
    }
}

.registry__list {
    width: 100%;
    position: relative;
    min-height: 444px;
    padding-top: 120px;
    @media screen and (max-width: 1220px) and (min-width: 744px) {
        padding-left: 20%;
        padding-right: 20%;
    }
    @media screen and (max-width: 744px) and (min-width: 560px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    h3 {
        font-size: 28px;
        line-height: 46px;
        margin-bottom: 60px;
        text-align: center;
    }
    .registry__list-wrapper {
        position: relative;
        display: flex;
        gap: 80px;
        height: 400px;
        .registry__item {
            display: flex;
            flex-direction: column;
            width: 40vw;
            height: 442px;
            padding: 60px;
            background: var(--white);
            border-radius: 36px;
            border: 10px solid #ea5a81;
            @media screen and (min-width: 2440px) {
                width: 30vw;
            }
            &:first-of-type {
                position: absolute;
                top: 0;
                left: -210px;
            }
            &:last-of-type {
                position: absolute;
                top: 0;
                right: -210px;
            }
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    filter: grayscale(100%);
                    margin-bottom: 36px;
                }
                .registry__item_padding {
                    padding: 54px 0 40px;
                }
            }
            p {
                color: var(--main700);
                text-align: center;
            }
        }
    }
}

/* ----------------- Tariffs Section */

.tariffs {
    width: 100%;
    padding: 160px 120px;
    text-align: center;
    background: #fff;
    @media screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 200px 60px;
    }
    @media screen and (max-width: 1280px) {
        padding: 100px 20px;

        .tariffs__list {
            flex-direction: column;
            .tariffs__name-wrapper .tariffs__name-info {
                position: static;
                margin-bottom: 16px;
            }
        }
    }
}

.tariffs__title {
    color: var(--main700);
    text-align: center;
    margin-bottom: 120px;
    @media screen and (max-width: 744px) {
        margin-bottom: 60px;
    }
}

.tariffs__list {
    display: flex;
    justify-content: space-between;
    gap: 60px;
}

.tariffs__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 42px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 24px;
    &:last-of-type {
        background: #0f0c30;
        color: var(--white);
    }
    @media screen and (max-width: 744px) {
        padding: 10px;
    }
}

.tariffs__item-wrapper {
    margin-bottom: 120px;
}

.tariffs__name-wrapper {
    display: flex;
    padding: 36px 42px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 20px;
    background: var(--transparent5);
    margin-bottom: 60px;
    @media screen and (max-width: 744px) {
        margin-bottom: 24px;
    }
    &.tariffs__name-wrapper_background-white {
        position: relative;
        background: var(--white);
        & .tariffs__name-info {
            position: absolute;
            top: 48px;
            right: 42px;
        }
    }
    & .tariffs__name {
        color: var(--main700);
        margin-bottom: 16px;
    }
    & .tariffs__name-subtitle {
        color: var(--main400);
        text-align: start;
    }
}

.tariffs__features-list {
    padding: 0;
    margin: 0;
    padding-left: 34px;
    color: var(--main700);
    &.color-wite li {
        color: var(--white);
    }
    & li {
        margin-bottom: 44px;
        text-align: start;
        @media screen and (max-width: 744px) {
            margin-bottom: 24px;
        }
    }
}

.tariffs__features-sublist {
    list-style: none;
    & li {
        margin: 0;
    }
}

/* ----------------- Details Section */

.details {
    display: flex;
    width: 100%;
    padding: 160px 200px;
    align-self: stretch;
    background: linear-gradient(103deg, rgba(230, 221, 230, 0.2) 10.99%, rgba(70, 92, 131, 0.2) 87.93%), #0f0c30;
    @media screen and (max-width: 1440px) {
        padding: 160px 60px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        padding: 100px 20px;
    }
    @media screen and (max-width: 744px) {

    }
}

.details__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    align-self: stretch;
}

.details__container-wrapper {
    gap: 12px;
}

.details__title {
    text-align: center;
    color: var(--white);
    margin-bottom: 16px;
}

.details__subtitle {
    text-align: center;
    color: var(--white);
}

.details__buttons {
    display: flex;
    width: 305px;
    flex-direction: column;
    justify-content: space-between;
    & .button:first-child {
        margin-bottom: 10px;
    }
    @media screen and (max-width: 400px) {
        width: 260px;
    }
}

/* ----------------- Footer Section*/

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 120px 200px;
    text-align: center;
    background: linear-gradient(336deg, rgba(255, 241, 245, 0.88) 4.74%, rgba(8, 34, 85, 0.26) 78.51%), #2a4977;
    @media screen and (max-width: 1440px) and (min-width: 960px) {
        padding: 120px 60px;
    }
    @media screen and (max-width: 960px) {
        padding: 60px 20px;
    }
}

.footer__title {
    color: var(--white);
    text-align: start;
}

.footer__subtitle {
    color: var(--white);
    text-align: start;
    margin-bottom: 160px;
    @media screen and (max-width: 960px) {
        margin-bottom: 60px;
    }
}

.footer__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
}

.footer__content-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 960px) {
        margin-bottom: 60px;
    }
}

.footer__content-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer__text {
    color: var(--white);
    text-align: start;
    &:first-child {
        margin-bottom: 42px;
    }
}

.footer__link {
    color: var(--white);
    text-align: start;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 42px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 1;
}

.popup_visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
}

.popup__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 60px;
    background: var(--white);
    position: relative;
    z-index: 1001;
    max-width: 1024px;
    overflow: hidden;
    padding: 42px 60px;
    margin: 0 20px;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-50px);
}

.popup__content.popup_visible{
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
}

.popup__close {
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: none;
    cursor: pointer;
    width: 36px;
    padding: 0px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    transition: color 0.2s ease;
    @media screen and (max-width: 744px) {
        top: 30px;
        right: 30px;
        svg {
            width: 24px;
        } 
    }
}

.popup__close:hover {
    color: red;
}

.popup-contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.popup__subtitle {
    color: var(--main700);
    margin-bottom: 22px;
    text-align: center;
}
