@import url("/src/styles.modyle.css");

.contact-form {
    width: 100%;
    padding: 160px 400px;
    text-align: center;
    background: var(--white);
    @media screen and (max-width: 1440px) and (min-width: 960px) {
        padding: 160px 60px;
    }
    @media screen and (max-width: 960px) {
        padding: 100px 20px;
    }
}

.contact-form__title {
    color: var(--main700);
}

.contact-form__subtitle {
    color: var(--main700);
    margin-bottom: 42px;
    @media screen and (max-width: 1440px) {
        margin-bottom: 28px;
    }
}

.contact-form__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.contact-form__input,
.contact-form__textarea,
.contact-form__select {
    display: flex;
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid #9DA9BC;
    background: var(--transparent5);
    color: var(--black);
    font-family: "inter";

    &::placeholder {
        color: var(--transparent30);
    }
    &:hover {
        border: 1px solid var(--transparent45);
        background: var(--white);
    }
    &:focus {
        border: 1px solid var(--main400);
        outline: none;
    }
    &:not(:placeholder-shown) {
        background: var(--white); /* Белый цвет фона при заполненном инпуте */
      }
}

.contact-form__select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('/public/arrow-down.svg') no-repeat right 16px center;
    background-size: 16px;
    cursor: pointer;
    &:hover {
        background: url('/public/arrow-down.svg') no-repeat right 16px center;
        background-size: 16px;
    }
    &:focus {
        background: url('/public/arrow-up.svg') no-repeat right 16px center;
        background-size: 16px;
    }
    & option {
        text-align: center;
        padding-top: 16px;
    }
}

.contact-form__input_error {
    border: 1px solid #D90113;
}

.contact-form__input.full-width {
    width: 100%;
}

.contact-form__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
}

.contact-form__textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 28px;
}

.contact-form__button {
    display: flex;
    width: 100%;
    padding: 20px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 20px;
    border: 3px solid var(--main500);
    background: var(--main500);

    color: var(--white);
    text-align: center;
    &:disabled {
        border: 3px solid var(--transparent45);
        opacity: 0.5;
        background: var(--transparent30);
        cursor: auto;
        color: var(--transparent45);
    }
    &:hover {
        background: var(--Color-palette-Main-100, #c6d6ff);
        color: var(--main500);
    }
    &::before {
        background: var(--Color-palette-Main-200, #96ABE1);
        color: var(--main500);
    }
}

.contact-form__consent {
    width: 100%;
    margin-top: 18px;
    color: var(--main500);
    text-align: left;
}

.contact-form__input-wrapper {
    position: relative;
    width: 100%;
}

.contact-form__input-wrapper.max-width {
    width: 100%;
}

.contact-form__input.max-width {
    width: 100%;
}

.contact-form__error {
    position: absolute;
    bottom: -23px;
    left: 8px;
    color: #D90113;
}

.custom-select-container {
    position: relative;
    width: 100%;
    max-height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid #9DA9BC;
    background: var(--transparent5, #F2F4F8);
    color: var(--black);
    cursor: pointer;
    &::placeholder {
        color: var(--transparent30);
    }
    &:hover {
        border: 1px solid var(--transparent45);
        background: var(--white);
    }
    &:focus {
        border: 1px solid var(--main400);
        outline: none;
    }
    &.selected {
        background-color: var(--white);
      }
  }
  
  .custom-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .custom-select .arrow {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/public/arrow-down.svg);
    background-repeat: no-repeat;
  }
  
  .custom-select .arrow.up {
    border-top: none;
    background-image: url(/public/arrow-up.svg);
  }
  
  .custom-select-options {
    position: absolute;
    top: 50px;
    left: 2px;
    width: 100%;
    z-index: 10;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  
  .custom-select-option {
    padding: 16px 42px;
    cursor: pointer;
    text-align: center;
    &:first-child {
        padding-top: 32px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    &:last-child {
        padding-bottom: 32px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    &:focus {
        background: var(--main0);
    }
    &:hover {
        background-color: var(--transparent5);
    }
  }

  .custom-select__placeholder {
    color: var(--transparent30);
  }