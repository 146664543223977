
.functions-messenger {
    display: flex;
    width: 100%;
    padding: 200px 0px;
    flex-direction: column;
    align-items: flex-start;
    background: var(--Color-palette-Info-0, #ede8fa);
    @media screen and (max-width: 1440px) {
        flex-direction: column;
    }
    @media screen and (max-width: 960px) {
        padding: 80px 0;
    }

    .functions-messenger__container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 200px;
        text-align: left;
        /* @media screen and (max-width: 1840px) {
            padding: 0 60px;
        } */
        @media screen and (max-width: 1440px) {
            flex-direction: column;
            padding: 0 60px;
        }
        @media screen and (max-width: 960px) {
            padding: 0 20px;
        }
        .functions-messenger__content {
            max-width: 658px;
            width: 100%;
            margin-right: 160px;
            @media screen and (max-width: 1840px) {
                margin-right: 0;
                & .functions-messenger__subtitle {
                    margin-bottom: 80px!important;
                }
            }
            @media screen and (max-width: 960px) and (min-width: 744px) {
                max-width: 370px;
                width: 100%;
            }
            .functions-messenger__title {
                font-weight: 700;
                text-align: start;
                color: var(--main700);
                margin-bottom: 16px;
                @media screen and (max-width: 1440px) {
                   text-align: center;
                }
            }
            .functions-messenger__subtitle {
                text-align: start;
                color: var(--main700);
                margin-bottom: 320px;
                @media screen and (max-width: 1440px) {
                    text-align: center;
                    margin-bottom: 48px;
                 }
            }
            .functions-messenger__button-wrapper {
                display: flex;
                gap: 24px;
                @media screen and (max-width: 1440px) {
                    justify-content: center;
                    gap: 16px;
                    margin-bottom: 32px;
                 }
                .functions-messenger__button {
                    background: transparent;
                    border: 0;
                    cursor: pointer;
                    width: 100px;
                    @media screen and (max-width: 960px) {
                        width: 60px;
                     }
                    .arrow-left-circle {
                        color: #012d78;
                        &:hover {
                            color: #8997E5;
                        }
                    }
                    &:disabled .arrow-left-circle {
                        color: #012d78;
                        opacity: 0.8;
                    }
                    .arrow-right-circle {
                        color: #012d78;
                        &:hover {
                            color: #8997E5;
                        }
                    }
                    &:disabled .arrow-right-circle {
                        color: #012d78;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
        .functions-messenger__slider {
            flex-grow: 1;
            overflow: hidden;
            max-width: 504px;
            height: 500px;
            padding-left: 4px;
            @media screen and (max-width: 960px) {
                max-width: 370px;
                height: 360px;
                margin-left: 14px;
            }
            @media screen and (max-width: 400px) {
                max-width: 303px;
                height: 303px;
                margin: 0;
                padding: 0;
            }

            &:first-of-type {
                padding-left: 0;
            }
            .functions-messenger__slider-wrapper {
                display: flex;
                transition: transform 0.5s ease-in-out;
                height: 100%;
                .functions-messenger__slide-item {
                    display: flex;
                    justify-content: center;
                    box-sizing: border-box;
                    border-radius: 80px;
                    margin-right: 12px;
                    background-color: #fff;
                    background: transparent;
                    &:nth-last-child(2) {
                        margin-right: 26px;
                    }
                    @media screen and (max-width: 960px) and (min-width: 400px){
                        border-radius: 30px;
                        margin-right: 10px;
                    }
                    @media screen and (max-width: 400px) {
                        &:nth-last-child(2) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

.slider__img {
    width: 500px;
    height: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: 960px) {
        width: 360px;
        height: 360px;
    }
    @media screen and (max-width: 400px) {
        max-width: 300px;
        height: 300px;
    }

    &.img_1 {
        background-image: url(/public/chat-folders.png);
    }
    &.img_2 {
        background-image: url(/public/mentions.png);
        @media screen and (max-width: 960px) {
            margin-left: 4px;
        }
        @media screen and (max-width: 400px) {
            margin-right: 2px;
            margin-left: 0;
        }
    }
    &.img_3 {
        background-image: url(/public/search.png);
        margin-left: 5px;
        @media screen and (max-width: 960px) {
            margin-left: 8px;
        }
        @media screen and (max-width: 400px) {
            margin-left: 0;
            margin-right: 3px;
        }
    }
    &.img_4 {
        background-image: url(/public/sending-reactions.png);
        margin-left: -10px;
        @media screen and (max-width: 400px) {
            margin-left: 4px;
        }
    }
}

.slider__content {
    padding: 22px;
    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        padding: 16px;
        & div.tag.captionL_Regular_16 {
            margin: 0 auto;
            text-align: center;
        }
    }
    .slider__content-wrapper {
        display: flex;
        justify-content: space-between;
        max-height: 220px;
        margin-bottom: 30px;
        @media screen and (max-width: 960px) {
            flex-direction: column;
            margin-bottom: 16px;
            height: 150px;
            br {
                display: none;
            }
        }
        .slider__content_title {
            color: var(--main700);
        }
        .slider__content_subtitle {
            color: var(--main700);
        }
    }
}
