@import url("/src/styles.modyle.css");

.faq {
    width: 100%;
    padding: 160px 200px;
    background: linear-gradient(25deg, rgba(255, 241, 245, 0.88) 27.39%, rgba(8, 34, 85, 0.26) 67.27%), #2A4977;
    @media screen and (max-width: 1440px) {
        padding: 160px 60px;
    }
    @media screen and (max-width: 744px) {
        padding: 100px 20px;
    }
}

.faq__title-wrapper {
    display: flex;
    margin-bottom: 100px;
    @media screen and (max-width: 1440px) {
        flex-direction: column;
        margin-bottom: 60px;
    }
}

.faq__title {
    color: var(--white);
    display: flex;
    text-align: center;
    a {
        color: #EF6BEA;
        text-decoration-line: underline;
        margin-left: 16px;
    }

    span {
        background-image: url(../../../public/arrow-right-up-line-pink.svg);
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
        color: #EF6BEA;
        margin-top: 16px;
    }
    @media screen and (max-width: 1440px) {
        text-align: start;
        span {
            margin-top: 20px;
        }
        a {
            color: #EF6BEA;
            text-decoration-line: underline;
            margin-left: 0;
        }
    }
    @media screen and (max-width: 744px) {
        span {
            width: 36px;
            height: 36px;
            margin-top: 12px;
        }
    }
    @media screen and (max-width: 420px) {
        span {
            width: 32px;
            height: 32px;
            margin-top: 8px;
        }
    }
    @media screen and (max-width: 320px) {
        span {
            width: 28px;
            height: 28px;
            margin-top: 6px;
        }
    }
}
.faq__container-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 60px;
    background: #0F0C30;
    border: 2px solid #EF6BEA;
    @media screen and (max-width: 744px) {
        border-radius: 24px;
    }
}

.faq__item {
    padding: 80px 100px;
    border-bottom: 2px solid #EF6BEA;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
    @media screen and (max-width: 1440px) {
        padding: 60px 60px 60px;
    }
    @media screen and (max-width: 744px) {
        padding: 24px 16px;
    }
}

.faq__question {
    color: #EF6BEA;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.faq__arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
    @media screen and (max-width: 1440px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    @media screen and (max-width: 744px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        img {
            width: 28px;
            height: 28px;
        }
    }
}

.faq__arrow.open {
    transform: rotate(180deg);
}

.faq__answer {
    color: var(--white);
    margin-bottom: 20px;
    margin-top: 20px;
}

.faq__answer ul {
    margin: 0 0 32px;
    padding-left: 50px;
}
