@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

:root {
    --black: #000;
    --main0: #d6e4ff;
    --main100: #b3cfff;
    --main400: #33486c;
    --main500: #012d78;
    --main700: #001a47;
    --green0: #c7fad5;
    --green400: #0f9f37;
    --orange0: #ffe3cf;
    --orange400: #d97101;
    --red0: #ffd2d6;
    --red400: #84010c;
    --white: #fff;
    --transparent2: rgba(179, 192, 214, 0.5);
    --transparent5: #f2f4f8;
    --transparent30: rgba(1, 45, 120, 0.30);
    --transparent45: rgba(1, 45, 120, 0.45);
    --color-palette-Accent-Secondary: #ddc2f4;
    --info0: #e0f6ff;
    --info-tertiary: #017cad;
    --info-secondary: #02a2e1;
}

h1 {
    font-size: 100px;
    line-height: 140px;
    
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 80px;
        line-height: 120px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 60px;
        line-height: 90px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 36px;
        line-height: 48px;
    }
    @media screen and (max-width: 320px) {
        font-size: 26px;
        line-height: 42px;
    }
}

h2 {
    font-size: 80px;
    line-height: 130px;

    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 60px;
        line-height: 110px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 42px;
        line-height: 80px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 42px;
        line-height: 68px;
    }
    @media screen and (max-width: 320px) {
        font-size: 28px;
        line-height: 40px;
    }
}

h3 {
    font-weight: 700;
    font-size: 60px;
    line-height: 78px;

    @media screen and (max-width: 1024px) and (min-width: 744px){
        font-size: 48px;
        line-height: 60px;
    }

    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 36px;
        line-height: 52px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 32px;
        line-height: 44px;
    }
    @media screen and (max-width: 320px) {
        font-size: 24px;
        line-height: 32px;
    }
}

h4 {
    font-size: 42px;
    font-weight: 700;
    line-height: 133%;
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        font-size: 42px;
        line-height: 56px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 32px;
        line-height: 46px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 24px;
        line-height: 36px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 22px;
        line-height: 32px;
    }
    @media screen and (max-width: 320px) {
        font-size: 16px;
        line-height: 24px;
    }
}

h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 157%;
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        font-size: 24px;
        line-height: 42px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 20px;
        line-height: 36px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 16px;
        line-height: 28px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 14px;
        line-height: 24px;
    }
    @media screen and (max-width: 320px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.bodyM_Regular_22 {
    font-size: 22px;
    line-height: 36px;
    font-weight: 400;

    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 14px;
        line-height: 22px;
    }
    @media screen and (max-width: 320px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.bodyS_Regular_22 {
    font-size: 22px;
    line-height: 36px;

    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 18px;
        line-height: 26px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 12px;
        line-height: 20px;
    }
    @media screen and (max-width: 320px) {
        font-size: 11px;
        line-height: 18px;
    }
}

.bodyS_Medium_24 {
    font-size: 22px;
    line-height: 36px;
    font-weight: 500;
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 18px;
        line-height: 26px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 12px;
        line-height: 20px;
    }
    @media screen and (max-width: 320px) {
        font-size: 11px;
        line-height: 18px;
    }
}

.bodyXS_Bold_18 {
    font-size: 18px;
    font-weight: 600;
    line-height: 177.778%;
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        font-size: 18px;
        line-height: 32px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 16px;
        line-height: 28px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 12px;
        line-height: 20px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 10px;
        line-height: 16px;
    }
    @media screen and (max-width: 320px) {
        font-size: 9px;
        line-height: 12px;
    }
}

.captionL_Regular_16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 1024px) and (min-width: 744px) {
        font-size: 10px;
        line-height: 18px;
    }
    @media screen and (max-width: 744px) and (min-width: 420px) {
        font-size: 10px;
        line-height: 18px;
    }
    @media screen and (max-width: 420px) and (min-width: 320px) {
        font-size: 10px;
        line-height: 16px;
    }
    @media screen and (max-width: 320px) {
        font-size: 8px;
        line-height: 12px;
    }
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Inter";
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.underline {
    text-decoration-line: underline;
}
