@import url("/src/styles.modyle.css");

.faq__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 54px 200px;
    background: #0F0C30;

    @media screen and (max-width: 1440px) {
        padding: 42px 60px;
    }

    @media screen and (max-width: 1440px) {
        padding: 20px;
    }

    .header__container {
        display: flex;
        width: 100% !important;
        padding: 0;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 160px;
        gap: 54px;
    }
    
    .header__link {
        display: flex;
        padding: 14px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--Color-palette-Main-0, #D6E4FF);
    
        text-align: center;
        text-decoration-line: underline;
    }

    .logo {
        height: 30px;
        width: 150px;
        background-image: url("../../../public/keepChat-logo.svg");
        background-repeat: no-repeat;
        outline: none;
      }     

    .faq__title {
        display: block;
        width: 100%;
        color: var(--white);
        text-align: center;
    }
    p {
        color: var(--Color-palette-Accent-Secondary, #DDC2F4);
    }

    .faq__subtitle {
        color: var(--white);
    }
    
    .faq__content {
        padding: 16px 24px;
    }
}