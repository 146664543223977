@import url("/src/styles.modyle.css");

.privacy-policy__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(144.25deg, rgb(55, 111, 181) 0.069%, rgb(2, 17, 72) 21.435%, rgb(55, 111, 181) 49.982%, rgb(2, 17, 72) 74.938%, rgb(55, 111, 181) 99.895%);
    margin: 0 auto;
    padding: 54px 200px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #2A4977;

    @media screen and (max-width: 1440px) {
        padding: 42px 60px;
    }

    @media screen and (max-width: 1440px) {
        padding: 20px;
    }

    .header__container {
        display: flex;
        width: 100% !important;
        padding: 0;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 160px;
        gap: 54px;
    }
    
    .header__link {
        display: flex;
        padding: 14px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #0F0C30;
    
        text-align: center;
        text-decoration-line: underline;
    }

    .logo_blue {
        height: 30px;
        width: 150px;
        background-image: url("../../../public/keepChat-logo-blue.svg");
        background-repeat: no-repeat;
        outline: none;
      }     

    .privacy-policy__title {
        color: #0F0C30;
        text-align: center;
    }

    .privacy-policy__subtitle {
        color: #0F0C30;
    }
    
    .privacy-policy__content {
        padding: 16px 24px;
    }
}